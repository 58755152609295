import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Template from './Template';
import Lightbox from './Lightbox';

const ConseilsBook = ({ children }) => {

	const orientation = useSelector(state => state.orientation);
	const DynamicComponent = orientation === "portrait" ? require('./coverPortrait.jpg') : require('./coverLandscape.jpg');

	useEffect(() => {
	}, [orientation]);
    return <Template title="Conseils pour réussir botre book photo de modèle - Samael : Photographe &agrave; Albi (Tarn - France)" citation="&Eacute;veillez vos sens &agrave; travers l'objectif de l'art et explorez l'intimit&eacute; sous un nouvel angle" image={DynamicComponent} >
        <div className="content">
            <section>
                <h2>Préparez-vous pour une Séance Réussie</h2>
                <p>Si vous êtes un modèle amateur aspirant à créer un book photo mémorable, suivez ces conseils pour vous préparer :</p>
                <ul>
                    <li><strong>Planifiez à l'avance :</strong> Discutez avec le photographe des concepts, des tenues et des lieux avant la séance.</li>
                    <li><strong>Choisissez vos tenues soigneusement :</strong> Apportez une variété de vêtements qui reflètent votre style et votre polyvalence.</li>
                    <li><strong>Prenez soin de vous :</strong> Hydratez-vous, reposez-vous bien et évitez les expositions au soleil excessives avant la séance.</li>
                </ul>
            </section>
            <section>
                <h2>Pendant la Séance Photo</h2>
                <p>Lors de la séance photo, gardez à l'esprit ces astuces pour obtenir les meilleures images possibles :</p>
                <ul>
                    <li><strong>Communiquez avec le photographe :</strong> Soyez ouvert à la communication, partagez vos idées et posez des questions si nécessaire.</li>
                    <li><strong>Explorez différentes expressions :</strong> Variez vos expressions faciales et corporelles pour capturer une gamme d'émotions.</li>
                    <li><strong>Faites confiance à votre instinct :</strong> Soyez vous-même, détendez-vous et laissez votre personnalité briller devant l'objectif.</li>
                </ul>
            </section>
            <section>
                <h2>Après la Séance</h2>
                <p>Une fois la séance terminée, assurez-vous de prendre ces mesures pour finaliser votre book photo :</p>
                <ul>
                    <li><strong>Sélectionnez vos meilleures images :</strong> Choisissez les photos qui représentent le mieux votre style et votre personnalité.</li>
                    <li><strong>Demandez des retouches si nécessaire :</strong> Si certaines images nécessitent des ajustements mineurs, n'hésitez pas à demander des retouches subtiles.</li>
                    <li><strong>Maintenez votre book à jour :</strong> Continuez à collaborer avec des photographes pour enrichir votre portfolio au fil du temps.</li>
                </ul>
            </section>
		</div>
	</Template>;
};

export default ConseilsBook;