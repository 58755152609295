import React from 'react';
//import Header from './Header';
import { NavLink } from 'react-router-dom';
import Contact from './Contact';
function Template(props) {
	let noForm = props.noForm != null;
	let isHome = props.isHome != null;
	document.title = props.title;

	function showMenu() {
		let nav = document.querySelector("nav ul");
		if (nav.classList.contains("showed")) {
			nav.classList.remove("showed");
		}
		else {
			nav.classList.add("showed");
		}
	}


	return <div className={isHome ? "home" : ""}>
		{/*<img src={logo} className="App-logo" alt="logo" />*/}
			<header>
			<nav>
				<button onClick={showMenu}>Ξ</button>
					<ul>
						<li>
							<NavLink to="/">
								Accueil
							</NavLink>
						</li>
						<li>
							<NavLink to="/Galerie-Samael-Photographe-Albi">
								Galerie
							</NavLink>
					</li>
						<li>
						<NavLink to="/Conseils-pour-devenir-modele">
								Conseils
						</NavLink>
						<ul>
							<li>
								<NavLink to="/Conseils-pour-devenir-modele">
									4 Conseils pour devenir modèle
								</NavLink></li>
							<li>
								<NavLink to="/Conseils-seance-photo-reussie-Photographe-Albi">
									Conseils en séance photo
								</NavLink></li>
							<li>
								<NavLink to="/Conseils-book-modele-photo-reussie-Photographe-Albi">
									Conseils pour réussir son book photo
								</NavLink></li>
						</ul>
					</li>
						<li>
						<NavLink to="/Noir-blanc-corps-nu-Photographe-Albi">
								Le noir et blanc
							</NavLink>
					</li>
						<li>
						<NavLink to="/Contacter-Samael-Photographe-Albi">
								Contact
							</NavLink>
					</li>
					</ul>
				</nav>
			</header>
			<div id="imageCitation" style={{ backgroundImage: `url(${props.image})` }} >
				<div className="citation">
					<h1>{props.title}</h1>
					{props.citation}
				</div>
		</div>
		<main>
				{props.children}
			<div>
				{ !noForm && <Contact /> }
			</div>
				<footer>
					<h2>Samael Photographie</h2>
					<p>Photographe &agrave; Albi (Tarn - France)</p>
				</footer>
			</main>
		</div>
}

export default Template;