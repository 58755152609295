import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Template from './Template';
import Lightbox from './Lightbox';

const Conseils = ({ children }) => {

	const orientation = useSelector(state => state.orientation);
	const DynamicComponent = orientation === "portrait" ? require('./coverPortrait.jpg') : require('./coverLandscape.jpg');

	useEffect(() => {
	}, [orientation]);
	return <Template title="4 conseils pour d&eacute;buter comme mod&egrave;les - Samael : Photographe &agrave; Albi (Tarn - France)" citation="&Eacute;veillez vos sens &agrave; travers l'objectif de l'art et explorez l'intimit&eacute; sous un nouvel angle" image={DynamicComponent} >
		<div className="content">
			<p>Vous débutez en tant que modèle photo? Voici quelques conseils pour commencer sereinement !</p>
			<section>
				<h2>1. Concevez Votre Book Photo en Ligne :</h2>
				<p className="specialSousH2">Votre book est une vitrine de votre talent et de votre style unique en tant que mod&egrave;le. Pour concevoir un book photo en ligne convaincant, commencez par s&eacute;lectionner une vari&eacute;t&eacute; de vos meilleures images de s&eacute;ance photo. Assurez-vous que ces images refl&egrave;tent votre diversit&eacute; et votre capacit&eacute; &agrave; exprimer diff&eacute;rentes &eacute;motions et styles. Organisez ces photos de mani&egrave;re professionnelle sur votre site web ou votre profil de mod&egrave;le en ligne. N'oubliez pas d'inclure une section qui parle de vous, de votre exp&eacute;rience et de votre passion pour la photographie. Votre book photo est une puissante carte de visite pour attirer l'attention des photographes et des agences, alors assurez-vous qu'il soit soign&eacute; et diiversifi&eacute;. Consultez d'autres books photo en ligne pour vous inspirer et mettre en valeur votre singularit&eacute; en tant que mod&egrave;le.</p>
			</section>
			<section>
				<h2>2. Votre Sécurit&eacute; en S&eacute;ance Photo :</h2>
			<p className="specialSousH2">En tant que mod&egrave;le (que vous soyiez amateur, professionnelle, d&eacute;butante ou exp&eacute;riment&eacute;e), la s&eacute;curit&eacute; en s&eacute;ance photo est une priorit&eacute; absolue. Avant de travailler avec un photographe, assurez-vous que le photographe prend toutes les mesures n&eacute;cessaires pour garantir votre bien-&ecirc;tre. Vous pouvez m&ecirc;me envisager de venir accompagn&eacute;e d'un ami ou d'un membre de votre famille lors de la s&eacute;ance photo, pour vous assurer que tout se d&eacute;roule en toute s&eacute;curit&eacute;. Un photographe responsable comprendra et respectera cela.</p>

			</section>
			<section>
				<h2>3. &Eacute;coutez Votre Instinct :</h2>
			<p className="specialSousH2">M&ecirc;me si vous &ecirc;tes mod&egrave;les d&eacute;butante, votre instinct est un guide pr&eacute;cieux. Si quelque chose vous semble &eacute;trange ou inconfortable lors d'une s&eacute;ance photo, ne l'ignorez pas. Vous n'&ecirc;tes jamais oblig&eacute;e de faire quelque chose qui ne vous met pas &agrave; l'aise. La communication avec le photographe est essentielle. Exprimez clairement vos limites et vos attentes pour la s&eacute;ance photo. Un bon photographe comprendra l'importance du respect mutuel entre le mod&egrave;le et le photographe. C'est la cl&eacute; pour cr&eacute;er un book photo exceptionnel tout en maintenant un environnement professionnel et respectueux.</p>

			</section>
			<section>
				<h2>4. Ne Vous Forcez Jamais :</h2>
			<p className="specialSousH2">Une r&egrave;gle d'or en tant que mod&egrave;le est de ne jamais vous forcer. Si une pose, une tenue ou une id&eacute;e de s&eacute;ance photo vous rend mal &agrave; l'aise, il est important de l'exprimer. Votre bien-&ecirc;tre physique et &eacute;motionnel est essentiel. Un photographe comp&eacute;tent et respectueux comprendra et respectera vos limites en tant que mod&egrave;le. Le but ultime est de cr&eacute;er un magnifique book photo qui refl&egrave;te votre beaut&eacute; naturelle et votre personnalit&eacute; authentique, et cela se fait en toute confiance et confort mutuels.</p>

			</section>
			<section>
				<h2>Les diff&eacute;rents styles de pose :</h2>
			<p className="specialSousH2">Voici une liste (non exhaustive) des diff&eacute;rents styles de pose.</p>
			<ul>
				<li><strong>Mode :</strong> Les poses de mode sont caract&eacute;ris&eacute;es par leur sophistication et leur &eacute;l&eacute;gance. Elles mettent en avant les v&ecirc;tements et accessoires, mettant en valeur le style et la beaut&eacute; du mod&egrave;le.</li>

				<li><strong>Portrait :</strong> Les poses de portrait se concentrent sur le visage et la personnalit&eacute; du sujet. Elles visent &agrave; capturer des &eacute;motions et des expressions authentiques.</li>

				<li><strong>Lifestyle :</strong> Les poses de style de vie capturent des moments spontan&eacute;s et naturels de la vie quotidienne. Elles racontent des histoires authentiques et d&eacute;contract&eacute;es.</li>

				<li><strong>Lingerie :</strong> Les poses en lingerie allient s&eacute;duction et subtilit&eacute;. Elles capturent la f&eacute;minit&eacute; et la confiance, cr&eacute;ant une ambiance sensuelle.</li>

				<li><strong>Boudoir :</strong> Les poses boudoir combinent &eacute;l&eacute;gance et sensualit&eacute;, cr&eacute;ant une atmosph&egrave;re intime et s&eacute;duisante. Elles sont souvent utilis&eacute;es pour des cadeaux personnels ou pour c&eacute;l&eacute;brer la confiance en soi.</li>

				<li><strong>Nu Artistique :</strong> Le nu artistique explore la beaut&eacute; du corps humain sous un angle artistique. Les poses sont souvent po&eacute;tiques, expressives et d&eacute;licates, cherchant &agrave; r&eacute;v&eacute;ler la gr&acirc;ce naturelle du mod&egrave;le.</li>

				<li><strong>&Eacute;rotisme :</strong> Les poses &eacute;rotiques &eacute;voquent la passion et la sensualit&eacute; de mani&egrave;re explicite mais artistique. Elles sont con&ccedil;ues pour susciter l'excitation tout en conservant une esth&eacute;tique soign&eacute;e.</li>

				<li><strong>Porn-Art :</strong> Les poses de porn-art transcendent la pornographie traditionnelle en int&eacute;grant des &eacute;l&eacute;ments artistiques et esth&eacute;tiques. Elles c&eacute;l&egrave;brent la beaut&eacute; du corps humain tout en explorant la sexualit&eacute; de mani&egrave;re artistique.</li>
			</ul>
			</section>
		</div>
	</Template>;
};

export default Conseils;