import { createStore } from 'redux';

const initialState = {
    orientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ORIENTATION':
            return { ...state, orientation: action.payload };
        default:
            return state;
    }
};

const store = createStore(rootReducer);

export default store;