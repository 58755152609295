import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Galerie from './Galerie';
import ContactPage from './ContactPage';
import Home from './Home';
import Conseils from './Conseils';
import ConseilsSeance from './ConseilsSeance';
import ConseilsBook from './ConseilsBook';
import LeNoirEtBlanc from './leNoirEtBlanc';
import E404 from './E404';
import ScrollToTop from "./ScrollToTop";
function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const mediaQuery = window.matchMedia('(orientation: landscape)');

        const handleOrientationChange = (event) => {
            const newOrientation = event.matches ? 'landscape' : 'portrait';
            dispatch({ type: 'SET_ORIENTATION', payload: newOrientation });
        };

        mediaQuery.addEventListener('change', handleOrientationChange);

        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange);
        };
    }, [dispatch]);
    return (
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<Home />} />
                <Route path="/Galerie-Samael-Photographe-Albi" element={<Galerie />} />
                    <Route path="/Conseils-pour-devenir-modele" element={<Conseils />} />
                <Route path="/Conseils-seance-photo-reussie-Photographe-Albi" element={<ConseilsSeance />} />
                <Route path="/Conseils-book-modele-photo-reussie-Photographe-Albi" element={<ConseilsBook />} />
                <Route path="/Noir-blanc-corps-nu-Photographe-Albi" element={<LeNoirEtBlanc />} />
                <Route path="/Contacter-Samael-Photographe-Albi" element={<ContactPage />} />
                <Route path="*" element={<E404 />} /> 
                </Routes>
            </BrowserRouter>
    );
}

export default App;
