import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Template from './Template';
import Lightbox from './Lightbox';

const ConseilsSeance = ({ children }) => {

	const orientation = useSelector(state => state.orientation);
	const DynamicComponent = orientation === "portrait" ? require('./coverPortrait.jpg') : require('./coverLandscape.jpg');

	useEffect(() => {
	}, [orientation]);
    return <Template title="Conseils pour une Séance Photo Réussie - Samael : Photographe &agrave; Albi (Tarn - France)" citation="&Eacute;veillez vos sens &agrave; travers l'objectif de l'art et explorez l'intimit&eacute; sous un nouvel angle" image={DynamicComponent} >
        <div className="content">
            <section>
                <h2>Pour les Modèles Amateurs</h2>
                <p>En tant que modèle amateur, vous vous lancez dans l'excitant monde de la photographie. Pour des séances photo réussies, il est essentiel de :</p>
                <ul>
                    <li><strong>Être à l'aise :</strong> Relaxez-vous devant l'objectif. Plus vous vous sentez à l'aise, plus vos photos seront naturelles et authentiques.</li>
                    <li><strong>Communiquer :</strong> Parlez ouvertement avec le photographe de vos idées, attentes et limites. La communication est la clé pour obtenir les résultats souhaités.</li>
                    <li><strong>Préparation :</strong> Soignez votre apparence et apportez différentes tenues pour diverses looks. Un bon maquillage et des accessoires peuvent faire la différence.</li>
                    <li><strong>Confiance en Soi :</strong> Ayez confiance en votre beauté naturelle. L'authenticité brille toujours à travers l'objectif.</li>
                </ul>
            </section>
            <section>
                <h2>Pour les Photographes</h2>
                <p>En tant que photographe, vous cherchez à créer des images exceptionnelles. Voici quelques conseils pour des séances photo réussies :</p>
                <ul>
                    <li><strong>Planification :</strong> Préparez-vous en avance en discutant avec le modèle des concepts, des lieux et des styles que vous souhaitez réaliser.</li>
                    <li><strong>Éclairage :</strong> Maîtrisez l'éclairage. La lumière peut transformer une photo ordinaire en une œuvre d'art.</li>
                    <li><strong>Direction du Modèle :</strong> Guidez le modèle avec des instructions claires pour obtenir les poses et expressions désirées.</li>
                    <li><strong>Post-Production :</strong> Ne sous-estimez pas l'importance de la retouche photo. Affinez vos images pour les rendre impeccables.</li>
                </ul>
            </section>
		</div>
	</Template>;
};

export default ConseilsSeance;