import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Template from './Template';
import Contact from './Contact';

const ContactPage = ({ children }) => {

	const orientation = useSelector(state => state.orientation);
	const DynamicComponent = orientation === "portrait" ? require('./coverPortrait.jpg') : require('./coverLandscape.jpg');
	useEffect(() => {
	}, [orientation]);
	return <Template noForm="ok" title="Contacter Samael : Photographe &agrave; Albi (Tarn - France)" citation="&Eacute;veillez vos sens &agrave; travers l'objectif de l'art et explorez l'intimit&eacute; sous un nouvel angle" image={DynamicComponent} >
		<Contact />
	</Template>;
};

export default ContactPage;