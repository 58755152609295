import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Template from './Template';

const LeNoirEtBlanc = ({ children }) => {

	const orientation = useSelector(state => state.orientation);
	const DynamicComponent = orientation === "portrait" ? require('./coverPortrait.jpg') : require('./coverLandscape.jpg');

	useEffect(() => {
	}, [orientation]);
    return <Template title="Noir et Blanc, Paysages Urbains, et Courbes du Corps - Samael : Photographe &agrave; Albi (Tarn - France)" citation="&Eacute;veillez vos sens &agrave; travers l'objectif de l'art et explorez l'intimit&eacute; sous un nouvel angle" image={DynamicComponent} >
        <div className="content">
            <section>
                <h2>L'Élégance du Noir et Blanc</h2>
                <p>La photographie en noir et blanc offre une perspective artistique unique. Explorez ma passion pour la monochromie :</p>
                <ul>
                    <li><strong>L'Intemporalité :</strong> le noir et blanc peut transcender le temps pour capturer l'essence intemporelle d'un instant.</li>
                    <li><strong>Le Contraste Émotionnel :</strong> le contraste entre le noir profond et le blanc éclatant peut renforcer les émotions dans une image.</li>
                    <li><strong>L'Expression Artistique :</strong> je m'exprime à travers cette forme d'art visuel en éliminant la distraction des couleurs.</li>
                </ul>
            </section>
            <section>
                <h2>Les Paysages Urbains en Noir et Blanc</h2>
                <p>Les paysages urbains offrent une toile fascinante pour la photographie.</p>
                <ul>
                    <li><strong>L'Architecture :</strong> la beauté architecturale des villes à travers des clichés en noir et blanc révèlent des détails extraordinaires.</li>
                    <li><strong>L'Énergie Urbaine :</strong> je capture l'énergie, le mouvement et la diversité culturelle des zones urbaines.</li>
                    <li><strong>La Poésie Urbaine :</strong> les rues, les bâtiments et les scènes quotidiennes se transforment en poésie visuelle en noir et blanc.</li>
                </ul>
            </section>
            <section>
                <h2>La Beauté des Courbes du Corps</h2>
                <p>Les courbes du corps humain sont une source infinie d'inspiration. Explorez ma passion pour la photographie qui célèbre la forme féminine :</p>
                <ul>
                    <li><strong>La Délicatesse des Courbes :</strong>je cherche à capturer la grâce, la délicatesse et la sensualité des courbes du corps féminin.</li>
                    <li><strong>L'Émotion à Travers la Forme :</strong> la forme du corps peut exprimer des émotions subtiles et puissantes à la fois.</li>
                    <li><strong>L'Art de la Confiance :</strong> mes modèles incarnent la confiance en elles-mêmes à travers la photographie artistique des courbes du corps.</li>
                </ul>
            </section>
		</div>
	</Template>;
};

export default LeNoirEtBlanc;