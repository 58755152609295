import React from 'react';

const Lightbox = ({ isOpen, imageUrl, onClose }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="lightbox" onClick={onClose}>
            <div className="lightbox-content">
                <img src={imageUrl} alt="Lightbox" />
            </div>
        </div>
    );
};

export default Lightbox;