import React, { useState } from 'react';

const FileUploadForm = () => {
    const [previewImages, setPreviewImages] = useState([]);

    const [randId] = useState(Date.now() + '-' + Math.round(Math.random() * 1E9));

    const handleFileUpload = async (event) => {
        event.preventDefault();

        for (const file of event.target.files) {
        const formData = new FormData();
            formData.append('randId', randId);
            formData.append('file', file);

            try {
                const response = await fetch('https://samael.photos/upload', { // Mettez &agrave; jour l'URL de l'API
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    setPreviewImages(prevImages => [
                        ...prevImages,
                        { name: file.name, dataURL: URL.createObjectURL(file) }
                    ]);
                }
            } catch (error) {
                console.error('Erreur lors de l\'envoi du fichier:', error);
            }
        }
    };

    const handleFileDelete = (fileName) => {
        setPreviewImages(prevImages =>
            prevImages.filter(image => image.name !== fileName)
        );
    };

    async function submit(event) {
        event.preventDefault();

        let envoyer = previewImages.length === 0 ? window.confirm("Envoyer sans image?") : true;

        if (envoyer) {
            const data = {};

            data.name = document.getElementById("contact-name").value;
            data.mail = document.getElementById("contact-email").value;
            data.tel = document.getElementById("contact-tel").value;
            data.message = document.getElementById("contact-message").value;
            data.randId = randId;

            try {
                const response = await fetch('https://samael.photos/message', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                if (response.ok) {
                    console.log('Donn&eacute;es envoy&eacute;es avec succ&egrave;s.');
                } else {
                    console.log('Erreur lors de l\'envoi des donn&eacute;es.');
                }
            } catch (error) {
                console.error('Erreur:', error);
            }
        }
    }


    return (
        <div id="contact" className="content">
            <h2>N'h&eacute;sitez pas &agrave; me contacter :</h2>
            <form onSubmit={submit} className="form-container" >
                <div className="form-column">
                    <input type="text" placeholder="Vos noms et pr&eacute;noms" id="contact-name" />
                <input type="email" placeholder="Votre email" id="contact-email" />
                    <input type="tel" placeholder="Votre t&eacute;l&eacute;phone" id="contact-tel" />
                <input type="file"
                multiple
                onChange={handleFileUpload}
                />
                </div>
                <div className="form-column">
                <textarea id="contact-message" placeholder="Votre message"></textarea>
                <input type="submit" value="Envoyer" />
                </div>




            </form>
            {previewImages.length > 0 &&
                <div className="formulaire-image-list">
                    {previewImages.map(image => (
                        <div key={image.name} className="formulaire-image-item">
                            <div className="formulaire-image-container">
                                <img
                                    src={image.dataURL}
                                    alt={image.name}
                                />
                            </div>
                            <button onClick={() => handleFileDelete(image.name)}>
                                Supprimer
                            </button>
                            <span>{image.name}</span>
                        </div>
                    ))}
                </div>}
        </div>
    );
};

export default FileUploadForm;