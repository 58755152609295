import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Galerie from './Galerie';
import Template from './Template';


function Home(props) {
    const orientation = useSelector(state => state.orientation);
    const DynamicComponent = orientation === "portrait" ? require('./coverPortrait.jpg') : require('./coverLandscape.jpg');
    useEffect(() => {
    }, [orientation]);
    return <Template isHome="ok" title="Samael : Photographe &agrave; Albi (Tarn - France)" citation="&Eacute;veillez vos sens &agrave; travers l'objectif de l'art et explorez l'intimit&eacute; sous un nouvel angle" image={DynamicComponent} >
        <div>
            <div class="content">
                <h2>Bienvenue dans mon univers photographique,</h2>
                <p className="specialSousH2">o&ugrave; l'art et l'&eacute;motion se rencontrent pour cr&eacute;er des images uniques et saisissantes.</p>
            <p>Bas&eacute; &agrave; Albi, je mets en lumi&egrave;re la beaut&eacute; authentique et la diversit&eacute; de l'exp&eacute;rience humaine &agrave; travers mon objectif. Mon travail consiste &agrave; capturer des instants intimes et significatifs, r&eacute;v&eacute;lant la profondeur des &eacute;motions et des connexions qui nous lient. Chaque clich&eacute; est une exploration artistique, une qu&ecirc;te pour saisir l'instant pr&eacute;cis o&ugrave; l'&acirc;me se d&eacute;voile. Mon approche sensible et respectueuse cr&eacute;e un espace o&ugrave; chacun peut se sentir &agrave; l'aise pour s'exprimer librement, permettant ainsi des images qui racontent des histoires authentiques et intemporelles. Explorez mon portfolio et laissez-vous emporter dans un voyage visuel qui c&eacute;l&egrave;bre la richesse de la vie &agrave; travers des moments fig&eacute;s dans le temps.</p>
            </div>
            <Galerie>8</Galerie>
            <div className="content">
                <p>Que vous soyez &agrave; la recherche d'une s&eacute;ance photo personnelle, d'une exploration artistique ou d'une mani&egrave;re unique de capturer des moments pr&eacute;cieux, je suis l&agrave; pour collaborer avec vous. Chaque projet est une opportunit&eacute; de cr&eacute;er des souvenirs durables et de donner vie &agrave; des &eacute;motions profondes. Mon approche attentive et cr&eacute;ative me permet de capturer des images qui r&eacute;sonnent avec authenticit&eacute; et sinc&eacute;rit&eacute;. En travaillant ensemble, nous pouvons transformer vos id&eacute;es en &#339;uvres d'art visuelles, capturant l'essence m&ecirc;me de chaque instant. Plongez dans mon univers photographique et d&eacute;couvrez comment mes images peuvent raconter votre histoire d'une mani&egrave;re unique et inoubliable.</p>
            </div>
        </div>
	</Template>;
};

export default Home;