import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, redirect } from 'react-router-dom';
import Template from './Template';

const E404 = ({ children }) => {

	const orientation = useSelector(state => state.orientation);
	const DynamicComponent = orientation === "portrait" ? require('./coverPortrait.jpg') : require('./coverLandscape.jpg');


	useEffect(() => {

		// Empêche l'indexation de la page 404 par les moteurs de recherche
		const metaTag = document.createElement('meta');
		metaTag.name = 'robots';
		metaTag.content = 'noindex, nofollow';
	document.head.appendChild(metaTag);
		setTimeout(() => {
			redirect("/");
		}, 3000);


		return () => {
			// Supprime la balise meta lors du démontage du composant
			document.head.removeChild(metaTag);
		};
	}, []);
	useEffect(() => {
	}, [orientation]);
	return <Template title="Erreur 404 - Samael : Photographe &agrave; Albi (Tarn - France)" citation="&Eacute;veillez vos sens &agrave; travers l'objectif de l'art et explorez l'intimit&eacute; sous un nouvel angle" image={DynamicComponent} >
		<div className="content">
			<h2>Cette page n'existe pas (ou plus)</h2>
			<p className="specialSousH2">Vous pouvez aller à l'<Link to="/">accueil</Link></p>

		</div>
	</Template>;
};

export default E404;