import React, { useEffect , useState } from 'react';
import { useSelector } from 'react-redux';
import Template from './Template';
import Lightbox from './Lightbox';

const Galerie = ({ children }) => {

	const [lightboxOpen, setLightboxOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');

	const handleImageClick = (imageUrl) => {
		setSelectedImage(imageUrl);
		setLightboxOpen(true);
	};

	const handleCloseLightbox = () => {
		setLightboxOpen(false);
	};
	const hasLimit = children != null;

	let list = [
		'a.jpg',
		'b.jpg',
		//'c.jpg',
		'd.jpg',
		'e.jpg',
		'f.jpg',
		'g.jpg',
		'h.jpg',
		'i.jpg',
		'j.jpg',
		'k.jpg',
		'l.jpg',
		'm.jpg'
	];

	if (hasLimit) {
		list = list.slice(0, children);
	}

	let list2 = [];

	for (let n of list) {
		list2.push(require("./resize/"+n));
	}


	const orientation = useSelector(state => state.orientation);
	useEffect(() => {
	}, [orientation]);

	if (!hasLimit) {
		const DynamicComponent = orientation === "portrait" ? require('./coverPortrait.jpg') : require('./coverLandscape.jpg');
		return <Template title="Galerie de Samael : Photographe &agrave; Albi (Tarn - France)" citation="&Eacute;veillez vos sens &agrave; travers l'objectif de l'art et explorez l'intimit&eacute; sous un nouvel angle" image={DynamicComponent} >
			<div className="content"><h2>Quelques exemples de mes r&eacute;alisations :</h2><p>Bienvenue dans ma galerie de photographies, un espace o&ugrave; les &eacute;motions prennent forme &agrave; travers l'objectif. Chaque image raconte une histoire unique, capturant des instants authentiques et des moments de vie. Explorez ces clich&eacute;s pour d&eacute;couvrir un monde d'&eacute;motions fig&eacute;es dans le temps, et laissez-vous emporter par les histoires qu'ils d&eacute;voilent.</p></div>
			<div className="image-grid">
				{list2.map((imageName, index) => (
					<div key={`gal item ${index + 1}`} className="grid-image">
						<div className="image-wrapper">
							<img src={imageName} alt={`gal item ${index + 1}`} onClick={() => handleImageClick(imageName)} />
					</div>
				  </div>
			))}
			</div>
			<Lightbox
				isOpen={lightboxOpen}
				imageUrl={selectedImage}
				onClose={handleCloseLightbox}
			/>
				</Template>;
	}
	else {
		return <div>
			<div className="image-grid">
				{list2.map((imageName, index) => (
				  <div key={`gal item ${index + 1}`} className="grid-image">
						<div className="image-wrapper">
							<img src={imageName} alt={`gal item ${index + 1}`} onClick={() => handleImageClick(imageName)} />
						</div>
				  </div>
				))}
			</div>
      <Lightbox
        isOpen={lightboxOpen}
        imageUrl={selectedImage}
        onClose={handleCloseLightbox}
      /></div>;
	}
};

export default Galerie;